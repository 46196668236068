const accordions = () => {
    const accordions = document.querySelectorAll('details.js-accordion');
    const accordionsReverse = document.querySelectorAll('.js-accordion-reverse');
    const qaData = [];

    if (accordions.length) {
        accordions.forEach(accordion => {
            const accordionTrigger = accordion.querySelector('summary');

            const addAriaAttributes = () => {        
                accordionTrigger.ariaExpanded = accordion.open;
    
                if (accordion.id) {
                    accordionTrigger.setAttribute('aria-controls', accordion.id);
                }
        
                accordion.addEventListener('toggle', e => {
                    accordionTrigger.ariaExpanded = e.newState === 'open';
                });
            };

            let question = accordionTrigger.children[0].textContent;
            let answer = accordion.querySelector('.accordion__content').textContent;
            question = question.replace(/\s+/g, ' ').trim();

            let qa = {
                '@context': 'https://schema.org',
                '@type': 'Question',
                'name': question,
                'acceptedAnswer': {
                    '@type': 'Answer',
                    'text': answer
                }
            }

            qaData.push(qa);
    
            addAriaAttributes();
        });
    }

    if (accordionsReverse.length) {
        accordionsReverse.forEach(accordion => {
            const accordionTrigger = accordion.querySelector('button');
            const accordionContent = accordion.querySelector('.js-accordion-content');
    
            const addAriaAttributes = () => {
                accordionTrigger.setAttribute('aria-expanded', accordionContent.classList.contains('is-open'));
                if (accordionContent.classList.contains('is-open')) {
                    accordion.classList.add('is-open');
                    accordion.classList.remove('is-closed');
                } else {
                    accordion.classList.remove('is-open');
                    accordion.classList.add('is-closed');
                }
            };
    
            accordionTrigger.addEventListener('click', () => {
                accordionContent.classList.toggle('is-open');
                accordionContent.classList.toggle('is-closed');
                addAriaAttributes();
            });
    
            addAriaAttributes();
        });
    }

    if (qaData.length) {
        const script = document.createElement('script');
        script.type = 'application/ld+json';

        const schemaData = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            'mainEntity': qaData
        }

        script.text = JSON.stringify(schemaData);


        document.head.appendChild(script);
    }

};

export default accordions;