const postsList = () => {
    const apiUrl = `${global.wpApiSettings.root}wp/v2/posts`;

    document.querySelectorAll('.js-posts-list').forEach(list => {
        const items = list.querySelector('.js-posts-list__items');
        const featured = list.querySelector('.js-posts-list__featured');
        const template = list.querySelector('.js-post-template');
        const loader = list.querySelector('.js-posts-list__loader');
        const pagination = list.querySelector('.js-posts-list__pagination');
        const categorySelect = list.querySelector('.js-posts-list__category-select');
        const categoryFilters = list.querySelectorAll('.js-posts-list__category-filter');
        const postsPerPage = list.dataset.postsPerPage || 6;
        const { featuredPostId, categories, ajax } = list.dataset;
        let totalPages = 0;
        let categoryId = '';
        let currentPage = 1;

        if (ajax === 'true') {
            const fetchPosts = (id, cb = () => {}) => {
                const params = new URLSearchParams();
                params.set('_fields', 'author,author_display_name,categories,category_names,date,excerpt.rendered,featured_media,featured_media_src,title,link');
                params.set('per_page', postsPerPage);
                params.set('page', currentPage);

                if (id) {
                    params.set('categories', id);
                } else {
                    if (categories) {
                        params.set('categories', categories);
                    }

                    if (featuredPostId) {
                        params.set('exclude[0]', featuredPostId);
                    }
                }

                fetch(`${apiUrl}?${params}`)
                    .then(res => {
                        totalPages = +res.headers.get('x-wp-totalpages');
                        return res.json();
                    })
                    .then(res => {
                        cb(res);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            };

            const buildPostsList = data => {
                loader.hidden = true;

                if (data.length) {
                    data.forEach(item => {
                        const title = item.title.rendered;
                        const excerpt = item.excerpt.rendered.replace(/<[^>]*>?/gm, '');
                        const node = template.content.cloneNode(true);
                        const nodeCategories = node.querySelector('.js-post-template__categories');
                        const nodeDate = node.querySelector('.js-post-template__date');
                        const nodeExcerpt = node.querySelector('.js-post-template__excerpt');
                        const nodeImage = node.querySelector('.js-post-template__image');
                        const nodeTitle = node.querySelector('.js-post-template__title');
                        const nodeLinks = node.querySelectorAll('.js-post-template__link');
                        const nodeAuthorName = node.querySelector('.js-post-template__author-name');

                        nodeTitle.innerHTML = title;

                        nodeLinks.forEach(nodeLink => {
                            nodeLink.href = item.link;
                        });

                        if (item.featured_media_src) {
                            nodeImage.innerHTML = `<img loading="lazy" class="w-100 h-100 object-fit-cover" src="${item.featured_media_src}" alt="" />`;
                        }

                        if (nodeDate) {
                            const date = new Date(item.date);
                            const { format } = nodeDate.dataset;
                            nodeDate.setAttribute('datetime', date.toISOString());
                            nodeDate.innerHTML = date.toLocaleDateString('en-US', {
                                month: format.includes('F') ? 'long' : 'short',
                                day: 'numeric',
                                year: 'numeric'
                            });
                        }

                        if (nodeCategories) {
                            item.category_names.forEach(name => {
                                nodeCategories.innerHTML += `<span>${name}</span>`;
                            });
                        }

                        if (nodeExcerpt) {
                            nodeExcerpt.innerHTML = excerpt;
                        }

                        if (nodeAuthorName) {
                            nodeAuthorName.innerHTML = item.author_display_name;
                        }

                        items.appendChild(node);
                    });

                    items.hidden = false;

                    if (featured) {
                        featured.hidden = !(currentPage === 1 && categoryId == '');
                    }

                    if (pagination) {
                        pagination.hidden = false;
                    }
                }
            };

            const buildPagination = () => {
                const buildPageUrl = page => {
                    const params = new URLSearchParams(global.location.search);
                    let url = global.location.pathname.replace(/\/$/, '');

                    if (url.includes('/page')) {
                        url = url.replace(/\/page\/\d+/g, `/page/${page}`);
                    } else {
                        url += `/page/${page}`;
                    }

                    url += `${params.size > 0 ? `?${params}` : ''}`;
                    
                    return url;
                };

                if (totalPages > 1) {
                    pagination.hidden = false;
                    pagination.innerHTML = '';

                    if (currentPage > 1) {
                        pagination.innerHTML = `<a class="wp-block-query-pagination-previous" href="${buildPageUrl(currentPage - 1)}" data-page="${currentPage - 1}">Previous Page</a>`;
                    }

                    pagination.innerHTML += '<div class="wp-block-query-pagination-numbers"></div>';
                    const numbers = pagination.querySelector('.wp-block-query-pagination-numbers');

                    if (totalPages <= 6) {
                        for (let i = 1; i <= totalPages; i++) {
                            if (i == currentPage) {
                                numbers.innerHTML += `<span class="page-numbers current" aria-current="page">${i}</span>`;
                            } else {
                                numbers.innerHTML += `<a class="page-numbers" href="${buildPageUrl(i)}" data-page="${i}">${i}</a>`;
                            }
                        }
                    } else if (currentPage <= 4) {
                        for (let i = 1; i <= 5; i++) {
                            if (i == currentPage) {
                                numbers.innerHTML += `<span class="page-numbers current" aria-current="page">${i}</span>`;
                            } else {
                                numbers.innerHTML += `<a class="page-numbers" href="${buildPageUrl(i)}" data-page="${i}">${i}</a>`;
                            }
                        }
                        numbers.innerHTML += '<span class="page-numbers dots" aria-hidden="true">&hellip;</span>';
                        numbers.innerHTML += `<a class="page-numbers" href="${buildPageUrl(totalPages)}" data-page="${totalPages}">${totalPages}</a>`;
                    } else if (currentPage >= totalPages - 3) {
                        numbers.innerHTML += `<a class="page-numbers" href="${buildPageUrl(1)}" data-page="1">1</a>`;
                        numbers.innerHTML += '<span class="page-numbers dots" aria-hidden="true">&hellip;</span>';
                        for (let i = totalPages - 4; i <= totalPages; i++) {
                            if (i == currentPage) {
                                numbers.innerHTML += `<span class="page-numbers current" aria-current="page">${i}</span>`;
                            } else {
                                numbers.innerHTML += `<a class="page-numbers" href="${buildPageUrl(i)}" data-page="${i}">${i}</a>`;
                            }
                        }
                    } else {
                        numbers.innerHTML += `<a class="page-numbers" href="${buildPageUrl(1)}" data-page="1">1</a>`;
                        if (currentPage == 4) {
                            numbers.innerHTML += `<a class="page-numbers" href="${buildPageUrl(2)}" data-page="2">2</a>`;
                        } else {
                            numbers.innerHTML += '<span class="page-numbers dots" aria-hidden="true">&hellip;</span>';
                        }
                        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                            if (i == currentPage) {
                                numbers.innerHTML += `<span class="page-numbers current" aria-current="page">${i}</span>`;
                            } else {
                                numbers.innerHTML += `<a class="page-numbers" href="${buildPageUrl(i)}" data-page="${i}">${i}</a>`;
                            }
                        }
                        numbers.innerHTML += '<span class="page-numbers dots" aria-hidden="true">&hellip;</span>';
                        numbers.innerHTML += `<a class="page-numbers" href="${buildPageUrl(totalPages)}" data-page="${totalPages}">${totalPages}</a>`;
                    }

                    if (currentPage < totalPages) {
                        pagination.innerHTML += `<a class="wp-block-query-pagination-next" href="${buildPageUrl(currentPage + 1)}" data-page="${currentPage + 1}">Next Page</a>`;
                    }
                } else {
                    pagination.hidden = true;
                }
            };

            const handleChangeCategory = category => {
                const pathname = global.location.pathname.replace(/\/page\/\d+/g, '');
                const params = category ? `category=${category}` : '';

                currentPage = 1;
                items.innerHTML = '';
                items.hidden = true;
                loader.hidden = false;

                if (featured) {
                    featured.hidden = true;
                }

                if (pagination) {
                    pagination.hidden = true;
                }

                global.history.pushState('', '', `${pathname}?${params}${global.location.hash}`);

                fetchPosts(categoryId, res => {
                    buildPostsList(res);
                    buildPagination();
                });
            };

            if (pagination) {
                pagination.addEventListener('click', e => {
                    if (e.target.nodeName === 'A') {
                        e.preventDefault();

                        currentPage = +e.target.dataset.page;
                        items.innerHTML = '';
                        pagination.innerHTML = '';

                        items.hidden = true;
                        loader.hidden = false;
                        pagination.hidden = true;

                        if (featured) {
                            featured.hidden = true;
                        }

                        global.history.pushState('', '', e.target.getAttribute('href'));

                        fetchPosts(categoryId, res => {
                            buildPostsList(res);
                            buildPagination();
                        });
                    }
                });
            }

            if (categorySelect) {
                categoryId = +categorySelect.value;

                categorySelect.addEventListener('change', e => {
                    categoryId = +e.target.value;
                    handleChangeCategory(e.target.options[e.target.selectedIndex].dataset.category);
                });
            }

            if (categoryFilters.length) {
                const selectedCategoryFilter = list.querySelector('.js-posts-list__category-filter.is-selected');
                categoryId = +selectedCategoryFilter.dataset.categoryId;

                categoryFilters.forEach(item => {
                    item.addEventListener('click', e => {
                        e.preventDefault();

                        categoryId = +item.dataset.categoryId;
                        handleChangeCategory(item.dataset.category);

                        categoryFilters.forEach(item => {
                            item.classList.remove('is-selected', 'fw-bold');
                        });

                        item.classList.add('is-selected', 'fw-bold');
                    });
                });
            }

            global.addEventListener('popstate', () => {
                const params = new URLSearchParams(global.location.search);

                if (categorySelect) {
                    if (params.get('category')) {
                        categorySelect.value = categorySelect.querySelector(`[data-category="${params.get('category')}"]`).value;
                    } else {
                        categorySelect.value = '';
                    }

                    categoryId = +categorySelect.value;
                }

                if (categoryFilters.length) {
                    const selectedCategory = list.querySelector(`.js-posts-list__category-filter[data-category="${params.get('category') ? params.get('category') : ''}"]`);

                    categoryFilters.forEach(item => {
                        item.classList.remove('is-selected', 'fw-bold');
                    });

                    selectedCategory.classList.add('is-selected', 'fw-bold');
                    categoryId = +selectedCategory.dataset.categoryId;
                }

                if (global.location.pathname.search(/\/page\/\d+/g) !== -1) {
                    currentPage = +location.pathname.split('/').filter(x => x).at(-1);
                } else {
                    currentPage = 1;
                }

                items.innerHTML = '';

                items.hidden = true;
                loader.hidden = false;

                if (featured) {
                    featured.hidden = true;
                }

                if (pagination) {
                    pagination.hidden = true;
                }

                fetchPosts(categoryId, res => {
                    buildPostsList(res);
                    buildPagination();
                });
            });
        }
    });
};

export default postsList;