const ctaBtns = () => {
    const ctaBtns = document.querySelectorAll('.js-cta-modal');

    ctaBtns.forEach(ctaBtn => {
        const btn = ctaBtn.querySelector('.js-cta-modal-btn');

        if (btn) {
            const modal = ctaBtn.querySelector('.js-cta-modal-content');
            const open = ctaBtn.querySelector('.js-cta-modal-btn-open');
            const close = ctaBtn.querySelector('.js-cta-modal-btn-close');

            btn.addEventListener('click', () => {
                modal.classList.toggle('is-open');
                btn.classList.toggle('is-active');
                open.classList.toggle('visually-hidden');
                close.classList.toggle('visually-hidden');
            });

            document.addEventListener('keydown', e => {
                if (e.key === 'Escape') {
                    modal.classList.remove('is-open');
                    btn.classList.remove('is-active');
                    open.classList.remove('visually-hidden');
                    close.classList.add('visually-hidden');
                }
            });

            document.addEventListener('click', e => {
                if (!ctaBtn.contains(e.target)) {
                    modal.classList.remove('is-open');
                    btn.classList.remove('is-active');
                    open.classList.remove('visually-hidden');
                    close.classList.add('visually-hidden');
                }
            });
        }
    });
}

export default ctaBtns;