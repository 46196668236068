const nav = () => {
    const navElem = document.querySelector('.js-nav');
    const navToggle = document.querySelector('.js-nav-toggle');

    if (navElem) {
        navElem.querySelectorAll('.menu-item-has-children a[href="#"]').forEach(item => {
            item.addEventListener('click', e => {
                e.preventDefault();
            });
        });

        if (navToggle) {
            const toggleNav = () => {
                const isOpen = navToggle.getAttribute('aria-expanded') === 'true';
                
                navToggle.classList.toggle('is-open', !isOpen);
                navElem.classList.toggle('is-open', !isOpen);
                navElem.setAttribute('aria-hidden', isOpen);
                navToggle.setAttribute('aria-expanded', !isOpen);
                document.body.classList.toggle('has-open-nav', !isOpen);
            };

            navToggle.addEventListener('click', toggleNav);

            document.addEventListener('keydown', e => {
                if (e.key === 'Escape' && navToggle.getAttribute('aria-expanded') === 'true') {
                    toggleNav();
                }
            });
        }
    }
};

export default nav;