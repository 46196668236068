const forms = () => {
    const footer = document.querySelector('.gform_footer');

    if (footer) {
        footer.style.display = 'flex;';
        footer.style.justifyContent = 'space-between';
        const requiredText = document.createElement('div');
        requiredText.innerHTML = '*Required';
        requiredText.style.fontSize = '12px';
        requiredText.style.fontWeight = '500';
        requiredText.style.fontStyle = 'italic';
        requiredText.style.color = '#CE3D45';
        footer.appendChild(requiredText);
    }
}

export default forms;