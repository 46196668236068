const anchorLinkSelect = () => {
    const select = document.querySelector('.js-anchor-link-select');
    const btn = document.querySelector('.js-anchor-link-btn');

    if (select && btn) {
        btn.addEventListener('click', () => {
            window.location.href = select.value;
        });
    }
}

export default anchorLinkSelect;