const header = () => {
    const header = document.querySelector('.js-header');
    const headerSearchOverlay = document.querySelector('.js-header__search-overlay');
    const offsetHeight = document.getElementById('wpadminbar')?.clientHeight || 0;

    if (header) {
        const setHeaderHeightCSSVar = () => {
            document.body.style.setProperty('--header-height', `${header.clientHeight}px`);
        }

        setHeaderHeightCSSVar();
        global.addEventListener('resize', setHeaderHeightCSSVar);

        if (header.classList.contains('js-header--show-on-scroll-up')) {
            let scrollPos = 0;

            global.addEventListener('scroll', () => {
                const { top } = document.body.getBoundingClientRect();
                const isSticky = header.classList.contains('position-sticky');

                const handleScrollTop = () => {
                    header.classList.add('site-header--top');
                    header.classList.remove('position-sticky');
                    header.style.transform = '';
                };

                const handleScrollUp = () => {
                    if (!isSticky) {
                        header.classList.add('position-sticky');
                        header.style.transition = 'none';
                        header.style.transform = 'translateY(-100%)';

                        global.setTimeout(() => {
                            header.style.transition = '';
                            header.style.transform = '';
                        });
                    }
                };

                const handleScrollDown = () => {
                    header.classList.remove('site-header--top');

                    if (isSticky) {
                        global.setTimeout(() => {
                            header.style.transform = 'translateY(-100%)';

                            global.setTimeout(() => {
                                header.classList.remove('position-sticky');
                            }, 250);
                        });
                    }
                };

                if (top >= offsetHeight) {
                    handleScrollTop();
                } else if (top > scrollPos) {
                    handleScrollUp();
                } else {
                    handleScrollDown();
                }

                scrollPos = top;
            });
        } else if (header.classList.contains('position-sticky')) {
            global.addEventListener('scroll', () => {
                const { top } = document.body.getBoundingClientRect();
                
                if (top >= offsetHeight) {
                    header.classList.add('site-header--top');
                } else {
                    header.classList.remove('site-header--top');
                }
            });
        }
    }

    if (headerSearchOverlay) {
        const headerSearchOverlayToggle = document.querySelector('.js-header__search-overlay-toggle');
        const headerSearchOverlayClose = document.querySelector('.js-header__search-overlay-close');

        const toggleSearchOverlay = () => {
            const isOpen = headerSearchOverlayToggle.getAttribute('aria-expanded') === 'true';

            headerSearchOverlayToggle.classList.toggle('is-open', !isOpen);
            headerSearchOverlay.classList.toggle('is-open', !isOpen);
            headerSearchOverlay.setAttribute('aria-hidden', isOpen);
            headerSearchOverlayToggle.setAttribute('aria-expanded', !isOpen);
        };

        headerSearchOverlayToggle.addEventListener('click', toggleSearchOverlay);
        headerSearchOverlayClose.addEventListener('click', toggleSearchOverlay);

        document.addEventListener('keydown', e => {
            if (e.key === 'Escape' && headerSearchOverlayToggle.getAttribute('aria-expanded') === 'true') {
                toggleSearchOverlay();
            }
        });
    }
};

export default header;