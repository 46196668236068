import accordions from './components/accordions';
import anchorLinkSelect from './components/anchor-link-select';
import ctaBtns from './components/floating-cta';
import forms from './utils/forms';
import header from './components/header';
import modals from './components/modals';
import nav from './components/nav';
import postsList from './components/posts-list';
import swiper from './components/swiper';
import tabs from './components/tabs';

accordions();
anchorLinkSelect();
ctaBtns();
forms();
header();
modals();
nav();
postsList();
swiper();
tabs();

// calculate scrollbar width and set the value in a css var
global.addEventListener('load', () => {
    document.body.style.setProperty('--scrollbar-width', `${window.innerWidth - document.body.clientWidth}px`);
});